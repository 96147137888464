import {
  ReactNode,
  useState,
} from "react";

import styles from "../../Styles/PageWrapper.module.css";

interface Props {
  title: string;
  titleChildren?: ReactNode;
  mobileTitleChildren?: ReactNode;
  mobile: boolean;
  headerChildren?: ReactNode;
  children: ReactNode;
}

/*
 * This is a wrapper for a section of the analysis page
 */
const PageWrapper = ({
  title,
  titleChildren,
  mobileTitleChildren,
  mobile,
  headerChildren,
  children,
}: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <div className={`content ${styles.graphContainer}`}>
      <div className={styles.graphHeaderMenu}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <span>{title}</span>
          </div>
          <div className={styles.headerContainer}>
            {!mobile && titleChildren}
            {mobile && (
              <div
                className={`${styles.headerButton} ${styles.mobileSettings}`}
                onClick={() => setMobileOpen(true)}
              />
            )}
          </div>
          {mobile && mobileOpen && (
            <div className={styles.mobileOptionsContainer}>
              <div className={styles.optionsHeader}>
                <span>{title} Options</span>
                <div
                  onClick={() => setMobileOpen(false)}
                  className={styles.exitMobileOptions}
                />
              </div>
              <div className={styles.optionsContent}>
                {mobileTitleChildren}
              </div>
            </div>
          )}
        </div>
        {headerChildren && <div className={styles.headerContainer}>
          {headerChildren}
        </div>}
      </div>
      {children}
    </div>
  );
};

export default PageWrapper;
