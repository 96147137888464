import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  lazy,
  Suspense,
  ReactNode,
  useContext,
} from "react";
import PopUp from "../PopUp";
import { User } from "@auth0/auth0-react";
import Spinner from "../Spinner";
import {
  handleCreateFolder,
  handleRenameFolder,
  handleRenameFile,
  handleDeleteFolder,
  handleDeleteFile,
  handleOpenFile,
  handleCreateGateway,
  handleCreateOrganization,
  editGateway,
  FileError,
  getFiles,
} from "../../Utils/FileUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Styles
import styles from "../../Styles/DeviceManager.module.css";
import DashboardTemperature from "../AnalysisPage/DashboardTemperature";
import DashboardAudio from "../AnalysisPage/DashboardAudio";
import InvitePopUp from "../InvitePage/InvitePopUp";
import { AppContext } from "../App";
import DashboardVibration from "../AnalysisPage/DashboardVibration";

// Modals
const GatewayModal = lazy(() => import("./SettingsModals/GatewayModal"));
const ShareSettingsModal = lazy(() => import("./SettingsModals/ShareSettingsModal"));
const SensorSettingsModal = lazy(() => import("./SettingsModals/SensorSettingsModal"));
const ReportBuilderModal = lazy(() => import("./ReportBuilderModal/ReportBuilderModal"));
const FileErrorModal = lazy(() => import("./FileErrorModal/FileErrorModal"));

const FileBrowser = lazy(() => import("../FileBrowserComponent"));

const errorCol = "#f90000";
const warningCol = "#ff9a2f";
const goodCol = "#3cd526";

const statusElem = goodCol;

const DEFAULT_LOCATION = {
  info: "University of Ottawa, 75, Laurier Avenue East, Byward Market, Rideau-Vanier, (Old) Ottawa, Ottawa, Eastern Ontario, Ontario, K1N 6N5, Canada",
  latLng: {
    lat: 45.42252705,
    lng: -75.68339041611182,
  },
};

interface Props {
  sortDevicesTabLast: () => void;
  user: User | undefined;
  ifAuthorized: (uType: "industry" | "researcher" | null, elem: ReactNode) => ReactNode;
}

const getSensorPermissions = async (sensorId: string, userID: string) => {
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "sensor",
        file_id: sensorId,
        user_id: userID,
        user_type: "industry",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;
  return permissionsVal;
};

// Creates a sensor JSON object for the device manager
const sensor = async (
  {
    id,
    sensor_name,
    file_path,
    sensor_id,
    types,
    battery_level,
    favourited,
  }: {
    id: string;
    sensor_name: string;
    file_path: string;
    sensor_id: string;
    types: string[];
    battery_level: string | null;
    favourited: boolean;
  },
  numId: number,
  openTab: any,
  userID: string,
  ifAuthorized: any,
  loadFiles: any
) => {
  const tabID = `${file_path}${sensor_name}`;

  // Get the status (danger/warning) notifications for this sensor
  // This means even if the latest samples are healthy the status will
  // display danger/warning if there are still
  // any undismissed notifications for this sensor until dismissal
  const status = await fetch(`/get_notifications/${id}/${userID}`).then(
    (res: any) => {
      return res.json();
    }
  );
  const color =
    status.length !== 0 && status[0].notify_type.endsWith("danger")
      ? errorCol
      : goodCol;

  // Get the user's permissions on the file
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "sensor",
        file_id: id,
        user_id: userID,
        user_type: "industry",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;

  return {
    dbID: id,
    key: tabID,
    isSensor: true,
    sensorId: sensor_id,
    batteryVolts: battery_level ? parseFloat(battery_level).toFixed(1) : null,
    isFavourite: favourited,

    toggleFavourite: () => {
      fetch(`/toggle_favourite/${id}/${!favourited}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 200) {
          // Reload the files
          loadFiles();
        } else {
          console.error(res);
        }
      });
    },
    analysisFunc:
      permissionsVal !== "viewer"
        ? () => {
            if (types.length === 0) {
              toast.info(
                `
                This sensor device has no sensors types activated. 
                Activate a sensor type in the sensor settings.
                `
              );
              return;
            }
            if (types.includes("vibration")) {
              // Get the axes that this sensor has measurements on (if any)
              fetch(`/get_sensor_axes/${id}`)
                .then((res: any) => {
                  return res.json();
                })
                .then((d: any) => {
                  const axes = d
                    .map((axis: any) => axis.axis)
                    .sort((a: any, b: any) => {
                      const order: any = { x: 1, y: 2, z: 3 };
                      return order[a] - order[b];
                    });
                  if (axes.length === 0) {
                    toast.info(
                      `
                      Vibration analysis is unavailable. 
                      No vibration data has been recorded for this sensor.
                      `
                    );
                  }
                  axes.forEach((axis: "x" | "y" | "z") => {
                    openTab(
                      id,
                      `${tabID}$/${axis}`,
                      `${sensor_name}: Vib. ${axis.toUpperCase()}`,
                      "vibration_analysis",
                      "chart-simple",
                      ifAuthorized(
                        "industry",
                        <DashboardVibration sensorId={numId} axis={axis}  />
                      )
                    );
                  });
                });
            }
            if (types.includes("temperature"))
              openTab(
                id,
                tabID,
                `${sensor_name}: Temperature`,
                "temperature_analysis",
                "chart-simple",
                ifAuthorized(
                  "industry",
                  <DashboardTemperature sensorId={numId}  />
                )
              );
            if (types.includes("audio"))
              openTab(
                id,
                tabID,
                `${sensor_name}: Audio`,
                "audio_analysis",
                "chart-simple",
                ifAuthorized(
                  "industry",
                  <DashboardAudio sensorId={numId}  />
                )
              );
          }
        : null,
    statusIcon: color,
    permissions: permissionsVal,
  };
};

// Creates a organization JSON object for the device manager
const org = async (
  files: any,
  dbID: string,
  location: string,
  userID: string
) => {
  const key = location;
  const errorStatus = files
    .filter(
      (f: any) =>
        f.isSensor &&
        (f.statusIcon === errorCol || f.statusIcon === warningCol) &&
        f.key.split("/")[0] + "/" === key
    )
    .map((f: any) => {
      return f.statusIcon;
    });
  let col = goodCol;
  if (errorStatus.length > 0) {
    if (errorStatus.includes(errorCol)) {
      col = errorCol;
    } else {
      col = warningCol;
    }
  }

  // Get the user's permissions on the file
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "org",
        file_id: dbID,
        user_id: userID,
        user_type: "industry",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;

  return {
    dbID: dbID,
    key: key,
    isOrg: true,
    statusIcon: col,
    permissions: permissionsVal,
  };
};

// Creates a gateway JSON object for the device manager
const gateway = async (
  files: any,
  dbID: string,
  name: string,
  location: string,
  id: string,
  password: string,
  description: string,
  userID: string
) => {
  const key = `${location}${name}/`;
  const errorStatus = files
    .filter(
      (f: any) =>
        f.isSensor &&
        (f.statusIcon === errorCol || f.statusIcon === warningCol) &&
        f.key.split("/").slice(0, -1).join("/") + "/" === key
    )
    .map((f: any) => {
      return f.statusIcon;
    });
  let col = goodCol;
  if (errorStatus.length > 0) {
    if (errorStatus.includes(errorCol)) {
      col = errorCol;
    } else {
      col = warningCol;
    }
  }

  // Get the user's permissions on the file
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "gateway",
        file_id: dbID,
        user_id: userID,
        user_type: "industry",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;

  return {
    dbID: dbID,
    key: key,
    isGateway: true,
    id: id,
    password: password,
    description: description,
    statusIcon: col,
    location: DEFAULT_LOCATION,
    permissions: permissionsVal,
  };
};

// Creates a folder JSON object for the device manager
const folder = async (
  files: any,
  dbID: string,
  key: string,
  userID: string
) => {
  const errorStatus = files
    .filter(
      (f: any) =>
        f.isSensor &&
        (f.statusIcon === errorCol || f.statusIcon === warningCol) &&
        f.key.slice(0, key.length) === key
    )
    .map((f: any) => {
      return f.statusIcon;
    });
  let col = goodCol;
  if (errorStatus.length > 0) {
    if (errorStatus.includes(errorCol)) {
      col = errorCol;
    } else {
      col = warningCol;
    }
  }

  // Get the user's permissions on the file
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "folder",
        file_id: dbID,
        user_id: userID,
        user_type: "industry",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;

  return {
    dbID: dbID,
    key: key,
    statusIcon: col,
    permissions: permissionsVal,
  };
};

/**
 * This is the main page of the industry side.
 * Here, the user can see all of their sensors and organize them in organizations, folders, and gateways.
 * He also has a notification system for when a sensor is not working properly.
 */
const DeviceManager = ({
  sortDevicesTabLast,
  user,
  ifAuthorized,
}: Props) => {
  const { tabInstance } =  useContext(AppContext) ?? {};
  const navigate = useNavigate();

  // Get File Data from DB
  const loadFiles = useCallback(() => {
    if (user?.sub) {
      getFiles(user?.sub, "industry").then((data) => {
        setFolders(data.folders);
        setSensors(data.sensors);
        setGateways(data.gateways);
        setOrgs(data.orgs);
      });
    }
  }, [user]);

  // The Files (Folders, Sensors, Gateways)
  const [files, setFiles] = useState<any>(null);
  const [folders, setFolders] = useState<any>(null);
  const [sensors, setSensors] = useState<any>(null);
  const [gateways, setGateways] = useState<any>(null);
  const [orgs, setOrgs] = useState<any>(null);

  // Notifications
  const [notifications, setNotifications] = useState<any[]>([]);

  // Open a new tab
  const openTab = useCallback(
    (
      sensorNumId: number,
      id: string,
      name: string,
      type: string,
      icon: string,
      panelComponent: any
    ) => {
      const tab_id = `$${type}$${id}`;
      const page = {
        id: tab_id,
        direction: "ltr",
        iconClass: `fa fa-${icon}`,
        title: name,
        lazy: true,
        panelComponent: () => panelComponent,
      };

      // Save Opened Tabs in Local Storage
      if (localStorage.getItem("tabs")) {
        localStorage.setItem(
          "tabs",
          JSON.stringify([
            ...JSON.parse(localStorage.getItem("tabs") as string),
            { sensorNumId: sensorNumId, ...page },
          ])
        );
      } else {
        localStorage.setItem(
          "tabs",
          JSON.stringify([{ sensorNumId: sensorNumId, ...page }])
        );
      }

      tabInstance.open(page).then(() => sortDevicesTabLast());
    },
    [tabInstance, sortDevicesTabLast]
  );

  const loadNotifications = () => {
    if (user?.sub && sensors) {
      fetch(`/notifications/${user?.sub}`)
        .then((res) => res.json())
        .then((data) => {
          Promise.all(
            data.map(async (d: any) => {
              const notif = {
                id: d.id,
                file: sensors.filter((s: any) => s.id === d.sensor_id)[0],
                type: d.notify_type,
                recommendations: d.recommendations,
                is_new: d.is_new,
              };
              return {
                id: notif.id,
                file: {
                  ...notif.file,
                  permissions: await getSensorPermissions(
                    notif.file.id,
                    user?.sub!
                  ),
                },
                type: notif.type,
                recommendations: notif.recommendations,
                is_new: notif.is_new,
              };
            })
          ).then((newNotifications) => {
            setNotifications(newNotifications);
          });
        });
    }
  };

  // Set a notification as seen or unseen
  const setNotificationSeen = (notification_id: number, is_new: boolean) => {
    if (user?.sub && sensors) {
      fetch(`/update_is_new_notification`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user?.sub,
          notification_id: notification_id,
          is_new: is_new,
        }),
      }).then(() => {
        loadNotifications();
      });
    }
  };

  useEffect(() => {
    loadNotifications();
    // eslint-disable-next-line
  }, [sensors]);

  useEffect(() => {
    // Load Files on Initialization
    loadFiles();
  }, [loadFiles]);

  // Make sure the files are in the correct format for the file browser
  useEffect(() => {
    if (folders && sensors && gateways && orgs) {
      // Load in files
      const newFiles: any = [];

      // Set the sensor files
      const settingSensors = new Promise<void>((resolve, reject) => {
        if (sensors.length === 0) resolve();
        sensors.forEach(async (f: any, i: number) => {
          if (user?.sub) {
            const file = await sensor(
              f,
              f.id,
              openTab,
              user?.sub,
              ifAuthorized,
              loadFiles
            );

            if (i === sensors.length - 1) resolve();
            newFiles.push(file);
          }
        });
      });
      settingSensors.then(() => {
        // Set the organization files
        const settingOrgs = new Promise<void>((resolve, reject) => {
          if (orgs.length === 0) resolve();
          orgs.forEach(async (f: any, i: number) => {
            if (user?.sub) {
              const file = await org(newFiles, f.id, f.file_path, user?.sub);

              if (i === orgs.length - 1) resolve();
              newFiles.push(file);
            }
          });
        });
        settingOrgs.then(() => {
          // Setting folder files
          const settingFolders = new Promise<void>((resolve, reject) => {
            if (folders.length === 0) resolve();
            folders.forEach(async (f: any, i: number) => {
              if (user?.sub) {
                const file = await folder(
                  newFiles,
                  f.id,
                  f.file_path,
                  user?.sub
                );

                if (i === folders.length - 1) resolve();
                newFiles.push(file);
              }
            });
          });
          settingFolders.then(() => {
            // Setting gateway files
            const settingGateways = new Promise<void>((resolve, reject) => {
              if (gateways.length === 0) resolve();
              gateways.forEach(async (f: any, i: number) => {
                if (user?.sub) {
                  const file = await gateway(
                    newFiles,
                    f.id,
                    f.gateway_name,
                    f.file_path,
                    f.auth_id,
                    f.password,
                    f.description,
                    user?.sub
                  );

                  if (i === gateways.length - 1) resolve();
                  newFiles.push(file);
                }
              });
            });
            settingGateways.then(() => {
              // Pushing loaded files into state
              setFiles(newFiles);
            });
          });
        });
      });
    }
  }, [
    openTab,
    folders,
    sensors,
    gateways,
    orgs,
    user?.sub,
    ifAuthorized,
    loadFiles,
  ]);

  const managerRef = useRef<any>(null);

  // Queue used for multifile actions (multi-delete and multi-move)
  const [queue, setQueue] = useState<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  // Queue for when multiple actions are done at the same time
  useEffect(() => {
    if (queue && queue.length > 0) {
      // Run first item in queue
      if (queue[0].action === "Move") {
        handleRenameFolder(
          files,
          queue[0].oldKey,
          queue[0].newKey,
          setFileErrorModalOpen,
          "Move",
          () => setQueue(queue.slice(1))
        );
      } else if (queue[0].action === "DeleteFolder") {
        handleDeleteFolder(files, [queue[0].key], () =>
          setQueue(queue.slice(1))
        );
      } else if (queue[0].action === "DeleteFile") {
        handleDeleteFile(
          files,
          [queue[0].key],
          () => setQueue(queue.slice(1)),
          "sensor"
        );
      }
    } else {
      loadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, loadFiles]);

  //Notification drop-down open/close state
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

  // Modal Open/Close States
  const [addReportModalOpen, setAddReportModalOpen] = useState(false);
  const [fileErrorModalOpen, setFileErrorModalOpen] =
    useState<FileError | null>(null);
  const [addGatewayModalOpen, setAddGatewayModalOpen] = useState(false);
  const [gatewaySettingsModalOpen, setGatewaySettingsModalOpen] =
    useState(false);
  const [shareSettingsModalOpen, setShareSettingsModalOpen] = useState(false);
  const [sensorSettingsModalOpen, setSensorSettingsModalOpen] = useState(false);

  // The name of the folder/gateway/sensor open in a modal
  const [modalItemName, setModalItemName] = useState<string>("");
  const [modalFile, setModalFile] = useState<any>();
  const [newOrgName, setNewOrgName] = useState("");
  const [newOrgTeam, setNewOrgTeam] = useState<any>();

  // New Gateway Modal
  const [newGatewayPath, setNewGatewayPath] = useState<string>("");
  const [newGatewayName, setNewGatewayName] = useState<string>("");
  const [newGatewayID, setNewGatewayID] = useState<string>("");
  const [newGatewayPassword, setNewGatewayPassword] = useState<string>("");
  const [newGatewayDescription, setNewGatewayDescription] =
    useState<string>("");
  const [newGatewayLocation, setNewGatewayLocation] =
    useState<any>(DEFAULT_LOCATION);

  // Report Builder Modal
  const [checkedAll, setCheckedAll] = useState<boolean>(true);
  const [overview, setOverview] = useState<boolean>(true);
  const [gatewayInfo, setGatewayInfo] = useState<boolean>(true);
  const [trends, setTrends] = useState<boolean>(true);
  const [lastMeasurements, setLastMeasurements] = useState<boolean>(true);
  const [reportFile, setReportFile] = useState<any>("");

  // Gateway Edit Modal
  const [gatewayData, setGatewayData] = useState<{
    name: string;
    description: string;
    id: string;
    password: string;
    location: {
      info: string;
      latLng: {
        lat: number;
        lng: number;
      };
    };
    path: string;
  } | null>(null);

  useEffect(() => {
    if (files) {
      const newFiles = files.map((f: any) => {
        if (gatewayData && f.id === gatewayData.id) {
          return {
            ...f,
            ...gatewayData,
          };
        } else {
          return f;
        }
      });
      setFiles(newFiles);
    }
    // eslint-disable-next-line
  }, [gatewayData]);

  // Threshold Warnings
  const WarningBanner = (props: {
    index: number;
    notification: any;
    permissions: string;
  }) => {
    const { index, notification, permissions } = props;
    const { id, file, type, recommendations, is_new } = notification;

    const [newRecommendation, setNewRecommendation] = useState<string>("");

    return (
      <div
        className={`${styles.dangerBox} ${is_new && styles.isNew} ${
          type.endsWith("danger") ? styles.danger : styles.warning
        }`}
        key={index}
      >
        <div className={styles.dangerDescription}>
          <b style={{ paddingRight: "0.5em" }}>
            {type.endsWith("danger") && <>DANGER:</>}
            {type.endsWith("warning") && <>WARNING:</>}
          </b>
          "{file.sensor_name}" exceeds {(type as string).split("_").join(" ")} threshold
          <div>
            <div
              className={`${
                is_new ? styles.unreadEnvelop : styles.readEnvelop
              }`}
              onClick={() => setNotificationSeen(id, !is_new)}
            />
            <div
              className={styles.openWarning}
              onClick={() => handleOpenFile(file, managerRef)}
            />
            <div
              className={styles.closeWarning}
              onClick={() => {
                fetch(`/delete_notifications`, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    notification_id: id,
                    user_id: user?.sub,
                  }),
                }).then((res) => {
                  if (res.status === 200) {
                    loadNotifications();
                  } else {
                    console.error(res);
                  }
                });

                // Reload the files
                loadFiles();
              }}
            />
          </div>
        </div>
        <div className={styles.recommendations}>
          {recommendations.length > 0 && (
            <>
              <b>Recommendations:</b>
              <ul>
                {recommendations
                  .filter(
                    (recommendation: any, index: number, self: any) =>
                      index ===
                      self.findIndex(
                        (r: any) =>
                          r.recommendation === recommendation.recommendation
                      )
                  )
                  .map((recommendation: any, i: number) => (
                    <div key={i}>
                      <li>{recommendation.recommendation}</li>
                      {permissions !== "viewer" && (
                        <div
                          className={styles.closeWarning}
                          onClick={() => {
                            fetch(`/recommendation`, {
                              method: "delete",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                recommendation: recommendation.recommendation,
                                notification_id: id,
                              }),
                            }).then((res) => {
                              if (res.status === 200) {
                                loadNotifications();
                              } else {
                                console.error(res);
                              }
                            });
                          }}
                        />
                      )}
                    </div>
                  ))}
              </ul>
            </>
          )}
          {permissions !== "viewer" && (
            <form
              className={styles.addRecommendation}
              onSubmit={(e) => {
                e.preventDefault();
                fetch("/recommendation", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    notification_id: id,
                    recommendation: newRecommendation,
                  }),
                }).then((res) => {
                  if (res.status === 200) {
                    loadNotifications();
                  } else {
                    console.error(res);
                  }
                });
              }}
            >
              <input
                type="text"
                placeholder="Add recommendation"
                value={newRecommendation}
                onChange={(e) => setNewRecommendation(e.target.value)}
              />
              <button type="submit">+</button>
            </form>
          )}
        </div>
      </div>
    );
  };

  // Get a SHA-256 hash of a string
  const hash = (string: string) => {
    const utf8 = new TextEncoder().encode(string);
    return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    });
  };

  if (!files) return <Spinner />;

  return (
    <>
      <InvitePopUp user={user} reloadFiles={() => loadFiles()} />
      <Suspense fallback={<></>}>
        {/* Create Report Modal */}
        <PopUp
          isOpen={addReportModalOpen}
          setIsOpen={setAddReportModalOpen}
          title={"Report Builder"}
        >
          <ReportBuilderModal
            checkedAll={checkedAll}
            setCheckedAll={setCheckedAll}
            overview={overview}
            setOverview={setOverview}
            gatewayInfo={gatewayInfo}
            setGatewayInfo={setGatewayInfo}
            trends={trends}
            setTrends={setTrends}
            lastMeasurements={lastMeasurements}
            setLastMeasurements={setLastMeasurements}
            reportFile={reportFile}
          />
        </PopUp>

        {/* Add Gateway Modal */}
        <PopUp
          closeManually={true}
          isOpen={addGatewayModalOpen}
          setIsOpen={setAddGatewayModalOpen}
          title={"New Gateway"}
          confirmBtnTxt={"Add Gateway"}
          confirmBtnFunc={async () => {
            // Check name issues
            if (newGatewayName.trim().length < 3) {
              toast.error("Name must be at least 3 characters long.");
              return;
            }
            // Check ID issues (must be unique)
            if (newGatewayID.length < 3) {
              toast.error("Unique ID must be at least 3 characters long.");
              return;
            } else if (newGatewayID.includes(" ")) {
              toast.error("Unique ID cannot contain spaces.");
              return;
            } else {
              const res = await fetch(`/gateway_exists/${newGatewayID}`);
              const exists = await res.json();
              if (exists) {
                toast.error("Unique ID not available, please choose a new ID.");
                return;
              }
            }
            // Check password issues
            if (newGatewayPassword.length < 3) {
              toast.error("Password must be at least 3 characters long.");
              return;
            } else if (newGatewayPassword.includes(" ")) {
              toast.error("Password cannot contain spaces.");
              return;
            }

            // Get SHA256 hash of password
            hash(newGatewayPassword).then((hashedPassword) => {
              // All requirements met, create gateway
              handleCreateGateway(
                files,
                user?.sub,
                {
                  name: newGatewayName.trim(),
                  key: newGatewayPath,
                  id: newGatewayID,
                  password: hashedPassword,
                  description: newGatewayDescription,
                  isGateway: true,
                  statusIcon: statusElem,
                  location: newGatewayLocation,
                },
                () => loadFiles(),
                setFileErrorModalOpen,
                setAddGatewayModalOpen
              );
            });
          }}
        >
          <GatewayModal
            newGateway
            file={null}
            gatewayLocation={newGatewayLocation}
            setGatewayLocation={setNewGatewayLocation}
            newGatewayName={newGatewayName}
            setNewGatewayName={setNewGatewayName}
            newGatewayID={newGatewayID}
            setNewGatewayID={setNewGatewayID}
            newGatewayPassword={newGatewayPassword}
            setNewGatewayPassword={setNewGatewayPassword}
            newGatewayDescription={newGatewayDescription}
            setNewGatewayDescription={setNewGatewayDescription}
          />
        </PopUp>

        {/* Gateway Settings Modal */}
        <PopUp
          isOpen={gatewaySettingsModalOpen}
          setIsOpen={setGatewaySettingsModalOpen}
          title={`"${gatewayData?.name}" Settings`}
          closeFunction={() => {
            const gw = {
              name: gatewayData?.name,
              description: gatewayData?.description,
              address: gatewayData?.location.info,
              latitude: gatewayData?.location.latLng.lat,
              longitude: gatewayData?.location.latLng.lng,
              authId: gatewayData?.id,
              password: gatewayData?.password,
              key: gatewayData?.path,
            };
            editGateway(gw, () => loadFiles());
          }}
        >
          <GatewayModal
            file={modalFile}
            gatewayLocation={gatewayData?.location}
            gatewayData={gatewayData}
            setGatewayData={setGatewayData}
            userEmail={user?.email}
          />
        </PopUp>

        {/* Sharing Settings Modal */}
        <PopUp
          isOpen={shareSettingsModalOpen}
          setIsOpen={setShareSettingsModalOpen}
          title={modalItemName}
          confirmBtnTxt={"Done"}
          confirmBtnFunc={() => {
            if (
              files
                .filter((f: any) => f.isOrg)
                .map((f: any) => f.key.split("/")[0])
                .includes(newOrgName)
            ) {
              toast.error(
                `
                An organization with this name already exists.
                Please pick a new name to continue.
                `
              );
            } else {
              if (!modalFile) {
                handleCreateOrganization(
                  newOrgTeam,
                  newOrgName,
                  () => loadFiles(),
                  setFileErrorModalOpen,
                  setShareSettingsModalOpen,
                  "industry"
                );
                setShareSettingsModalOpen(false);
              }
            }
          }}
        >
          <ShareSettingsModal
            closeModal={() => setShareSettingsModalOpen(false)}
            file={modalFile}
            user={user}
            setNewOrgName={setNewOrgName}
            newOrgName={newOrgName}
            setNewOrgTeam={setNewOrgTeam}
            userType="industry"
          />
        </PopUp>

        {/* Sensor Settings Modal */}
        <PopUp
          isOpen={sensorSettingsModalOpen}
          setIsOpen={setSensorSettingsModalOpen}
          title={`"${modalItemName}" Settings`}
        >
          <SensorSettingsModal
            file={modalFile}
            sensorName={modalItemName}
            sensors={sensors}
            setSensors={setSensors}
          />
        </PopUp>

        {/* File Action Error Modal */}
        <PopUp
          isOpen={fileErrorModalOpen !== null}
          setIsOpen={() => setFileErrorModalOpen(null)}
          title={
            fileErrorModalOpen
              ? `${fileErrorModalOpen?.fileType} ${fileErrorModalOpen?.actionType} Failed`
              : ""
          }
        >
          <FileErrorModal fileError={fileErrorModalOpen} />
        </PopUp>
      </Suspense>

      {/* File Browser Plugin
       *** For plugin development instructions/tips see:
       *** https://github.com/chrisvenczel/openPHM/wiki/Device-Manager */}
      <div className={`page`}>
        <div className={`content ${styles.filebrowser}`}>
          <div className={styles.warnings}>
            <div
              className={`${styles.notificationDropdown} ${
                notificationsOpen && styles.notificationOpen
              }`}
            >
              <div
                className={styles.dropdownButton}
                style={{ paddingBottom: notificationsOpen ? "5px" : "0px" }}
                onClick={() =>
                  setNotificationsOpen(
                    (notificationsOpen) => !notificationsOpen
                  )
                }
              >
                <b>Notifications</b>
                {notifications.map((notif) => notif.is_new).includes(true) && (
                  <span className={styles.newNotifCount}>
                    {notifications.reduce(
                      (acc, obj) => (obj.is_new ? acc + 1 : acc),
                      0
                    )}
                  </span>
                )}
                <span
                  className={styles.dropdownArrow}
                  style={{
                    transform: notificationsOpen
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                ></span>
              </div>
              <div
                className={`${styles.notifications} ${
                  notifications.length > 0 ? styles.hasFirstChild : ""
                }`}
              >
                {notificationsOpen && notifications.length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    No notifications to display.
                  </div>
                )}
                {notifications.map((val: any, i: number) => {
                  return (
                    notificationsOpen && (
                      <div key={i}>
                        <WarningBanner
                          index={i}
                          notification={val}
                          permissions={val.file.permissions}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
          <Suspense
            fallback={
              <div className={styles.deviceManagerLoader}>
                <Spinner />
              </div>
            }
          >
            <FileBrowser
              mapProps={{
                gateways: gateways,
                setModalFile: setModalFile,
                setGatewayData: setGatewayData,
                setGatewaySettingsModalOpen: setGatewaySettingsModalOpen,
              }}
              gateways={gateways}
              browserType="sensor"
              innerRef={managerRef}
              files={files}
              icons={{
                // File Icons
                Org: <i className="orgFileIcon" aria-hidden="true" />,
                Gateway: <i className="gatewayFileIcon" aria-hidden="true" />,
                Sensor: <i className="sensorFileIcon" aria-hidden="true" />,
                Folder: <i className="folderFileIcon" aria-hidden="true" />,
                FolderOpen: (
                  <i className="folderOpenFileIcon" aria-hidden="true" />
                ),
                FolderAdd: (
                  <i className="folderAddFileIcon" aria-hidden="true" />
                ),

                // Button Icons
                Rename: <i className="renameFileIcon" aria-hidden="true" />,
                Delete: <i className="deleteFileIcon" aria-hidden="true" />,
                GatewayAdd: (
                  <i className="gatewayAddFileIcon" aria-hidden="true" />
                ),
                OrganizationAdd: (
                  <i className="organizationAddFileIcon" aria-hidden="true" />
                ),
                ReportAdd: (
                  <i className="reportAddFileIcon" aria-hidden="true" />
                ),
                Tutorial: <i className="tutorialFileIcon" aria-hidden="true" />,
                Share: <i className="shareFileIcon" aria-hidden="true" />,
                LeaveOrg: <i className="leaveOrgFileIcon" aria-hidden="true" />,
              }}
              onLeaveOrg={(org: any) => {
                fetch(`/get_org/${org[0]}`)
                  .then((res) => res.json())
                  .then((orgId) => {
                    if (!orgId) return;
                    fetch(`/remove_permission/${orgId}/${user?.sub}`, {
                      method: "POST",
                    }).then(() => loadFiles());
                  });
              }}
              onCreateFolder={(key: string) => {
                handleCreateFolder(
                  files,
                  user?.sub,
                  key,
                  () => loadFiles(),
                  setFileErrorModalOpen
                );
              }}
              onMoveFolder={
                files
                  .filter((f: any) =>
                    selectedFiles.some((s: any) => s === f.key)
                  )
                  .filter(
                    (f: any) => !f.permissions?.includes("viewer") && !f.isOrg
                  ).length > 0
                  ? (oldKey: string, newKey: string) => {
                      const newActions = selectedFiles
                        .map((key: string) => {
                          if (
                            queue.filter((action: any) => action.key === key)
                              .length > 0
                          ) {
                            return null;
                          } else {
                            const path =
                              newKey.split("/").length === 2
                                ? ""
                                : newKey.split("/").slice(0, -2).join("/") +
                                  "/";
                            const fileName = key.split("/").reverse()[1] + "/";
                            const newPath = path + fileName;
                            return {
                              action: "Move",
                              oldKey: key,
                              newKey: newPath,
                            };
                          }
                        })
                        .filter((e: any) => e !== null);
                      setQueue([...queue, ...newActions]);
                    }
                  : undefined
              }
              onRenameFolder={(oldKey: string, newKey: string) =>
                handleRenameFolder(
                  files,
                  oldKey,
                  newKey,
                  setFileErrorModalOpen,
                  "Rename",
                  () => loadFiles()
                )
              }
              onRenameFile={(oldKey: string, newKey: string) =>
                handleRenameFile(
                  files,
                  oldKey,
                  newKey,
                  loadFiles,
                  setFileErrorModalOpen,
                  "Sensor"
                )
              }
              onDeleteFolder={(key: string) =>
                handleDeleteFolder(files, key, () => loadFiles())
              }
              onDeleteFile={(key: string) =>
                handleDeleteFile(files, key, () => loadFiles(), "sensor")
              }
              confirmMultipleDeletionRenderer={() => (
                <div className={"deleting"}>
                  <button
                    style={{
                      marginTop: "0",
                      width: "100%",
                    }}
                    onClick={() => {
                      const newActions = selectedFiles
                        .map((key: string) => {
                          if (
                            queue.filter((action: any) => action.key === key)
                              .length > 0
                          ) {
                            return null;
                          } else {
                            const isSensor = key.split("/").reverse()[0] !== "";
                            return {
                              action: isSensor ? "DeleteFile" : "DeleteFolder",
                              key: key,
                              isSensor: isSensor,
                            };
                          }
                        })
                        .filter((e: any) => e !== null)
                        .sort((x: any, y: any) => {
                          return y.isSensor - x.isSensor;
                        });
                      setQueue([...queue, ...newActions]);
                    }}
                  >
                    Confirm Multiple Deletions
                  </button>
                </div>
              )}
              renderStyle={"table"}
              // Custom Functions
              setSelectedFiles={(e: any) => setSelectedFiles(e)}
              sensorSettings={(sensorId: string) => {
                const file = files.filter((f: any) => f.dbID === sensorId)[0];
                setModalFile(file);
                setModalItemName(file.key.split("/").pop());
                setSensorSettingsModalOpen(true);
              }}
              gatewaySettings={(data: any) => {
                const gw = gateways.filter(
                  (g: any) => g.auth_id === data.id
                )[0];
                const gwData = {
                  description: gw.description,
                  id: gw.auth_id,
                  location: {
                    info: gw.address,
                    latLng: {
                      lat: gw.latitude,
                      lng: gw.longitude,
                    },
                  },
                  name: gw.gateway_name,
                  password: gw.password,
                  path: gw.file_path,
                };
                setModalFile(
                  files.filter((f: any) => f.isGateway && f.id === data.id)[0]
                );
                setGatewayData(gwData);
                setGatewaySettingsModalOpen(true);
              }}
              orgSettings={(file: any) => {
                setModalItemName(
                  `${file.name} - ${
                    file.permissions === "owner" ? "Manage" : "View"
                  } Team`
                );
                setModalFile(file);
                setShareSettingsModalOpen(true);
              }}
              addGateway={(file: any) => {
                setNewGatewayPath(file === "root" ? "" : file.key);
                setNewGatewayName("");
                setNewGatewayID("");
                setNewGatewayDescription("");
                setNewGatewayPassword("");
                setAddGatewayModalOpen(true);
              }}
              goToTutorial={() => {
                tabInstance.select("");
                tabInstance.refresh();
                setTimeout(() => {
                  navigate("/help/industry_tutorial_overview");
                }, 0);
              }}
              addReport={(file: any) => {
                setReportFile(
                  file === "root"
                    ? {
                        children: files,
                        name: "All Devices",
                      }
                    : file
                );
                setCheckedAll(true);
                setOverview(true);
                setGatewayInfo(true);
                setTrends(true);
                setLastMeasurements(true);
                setAddReportModalOpen(true);
              }}
              addOrganization={() => {
                setModalItemName(`New Organization`);
                setNewOrgName("");
                setModalFile(null);
                setShareSettingsModalOpen(true);
              }}
            />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default DeviceManager;
