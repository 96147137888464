import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useOutsideDetect } from "../../Utils/DropdownUtils";

import styles from "../../Styles/TypeDropdown.module.css";
import { capitalizeFirstLetter } from "../../Utils/Utils";

const TypeAndAxisDropdown = ({
  type,
  setType,
}: {
  type: "acceleration" | "velocity" | "audio";
  setType: Dispatch<SetStateAction<"acceleration" | "velocity" | "audio">>;
}) => {
  const [typeDropdownOpen, setTypeDropdownOpen] = useState<boolean>(false);

  const typeBtnRef = useRef<HTMLDivElement>(null);
  const typeDropdownRef = useRef<HTMLDivElement>(null);
  useOutsideDetect(typeBtnRef, typeDropdownRef, () => setTypeDropdownOpen(false));

  return (
    <div className={styles.bothWrapper}>
      <div className={styles.wrapper}>
        <div
          ref={typeBtnRef}
          className={styles.button}
          onClick={() => setTypeDropdownOpen(!typeDropdownOpen)}
        >
          <span>{capitalizeFirstLetter(type)}</span>
          <div
            className={styles.downArrow}
            style={{
              transform: `translateY(1px) ${
                typeDropdownOpen ? "rotate(180deg)" : ""
              }`,
            }}
          />
        </div>

        {typeDropdownOpen && (
          <div
            className={styles.dropdown}
            ref={typeDropdownRef}
          >
            <div
              className={styles.result}
              onClick={() => {
                setType("acceleration");
                setTypeDropdownOpen(false);
              }}
            >
              Acceleration
            </div>
            <div
              className={styles.result}
              onClick={() => {
                setType("velocity");
                setTypeDropdownOpen(false);
              }}
            >
              Velocity
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TypeAndAxisDropdown;