import {
  useState,
  useEffect,
  lazy,
  Suspense,
} from "react";
import styles from "../../Styles/Dashboard.module.css";
import LoadingPage from "./LoadingPage";

import {
  FFT_LAYER_COLORS,
  LeftView,
  RightView,
} from "./DashboardUtils";
import TrendsGraph from "./TrendsGraph/TrendsGraph";
import FFTAnalysis from "./FFTAnalysis/FFTAnalysis";
import { FFTLayer, MachineSettings, Sample } from "./HTTPRequests/Types";
import { getAnalysisSensorSamples } from "./HTTPRequests/IndustryData";
import { getMachineSettings } from "./HTTPRequests/Settings";
import { addFFTLayer, getFolderFFTLayers, getSensorFFTLayers } from "./HTTPRequests/FFTLayers";
import { getAnalysisFolderSamples } from "./HTTPRequests/ResearchData";

// Sections / Views

const FFTLayers = lazy(() => import("./FFTLayers/FFTLayers"));
const SampleGraph = lazy(() => import("./SampleGraph/SampleGraph"));
const MachineSettingsView = lazy(() => import("./MachineSettings/MachineSettingsView"));

interface Props {
  sensorId?: number; // for industry users
  folderPath?: string; // for research users
  axis?: "x" | "y" | "z";
  readonly?: boolean;
}

/*
 * This is the vibration analysis dashboard for industry users
 */
const Dashboard = ({ sensorId, folderPath, axis, readonly }: Props) => {
  const [leftView, setLeftView] = useState<LeftView>(LeftView.TrendsGraph);
  const [rightView] = useState<RightView>(RightView.FFTAnalysis);

  const [mobile, setMobile] = useState(false);

  const [fftLayers, setFFTLayers] = useState<FFTLayer[]>([]);
  const [machineSettings, setMachineSettings] = useState<MachineSettings | undefined>();
  const [selectedSample, setSelectedSample] = useState<Sample>();
  const [samples, setSamples] = useState<Sample[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 550) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  useEffect(() => {
    if (sensorId) {
      getAnalysisSensorSamples(sensorId, "vibration", axis ?? "x").then((samples) => {
        setSamples(samples.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()));
      }).catch(e => console.log(e));
  
      getMachineSettings(sensorId).then((settings) => {
        setMachineSettings(settings);
      }).catch(e => console.log(e));
  
      getSensorFFTLayers(sensorId, "vibration", axis ?? "x").then((layers) => {
        setFFTLayers(layers);
      }).catch(e => console.log(e));
    } else if (folderPath) {
      getAnalysisFolderSamples(folderPath, "vibration", axis ?? "x").then((samples) => {
        setSamples(samples.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()));
      }).catch(e => console.log(e));

      getFolderFFTLayers(folderPath, "vibration", axis ?? "x").then((layers) => {
        setFFTLayers(layers);
      }).catch(e => console.log(e));
    }
  }, [sensorId, folderPath, axis]);

  const goToSample = (sample: Sample) => {
    setSelectedSample(sample);
    setLeftView(LeftView.SampleGraph);
  }

  const loadLayers = async () => {
    if (sensorId) {
      try {
        const layers = await getSensorFFTLayers(sensorId, "vibration", axis ?? "x");
        setFFTLayers(layers);
      } catch (e) {
        console.log(e);
      }
    } else if (folderPath) {
      try {
        const layers = await getFolderFFTLayers(folderPath, "vibration", axis ?? "x");
        setFFTLayers(layers);
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <div className={`page ${styles.graphPage}`}>
      
      {/* Vibration Sample View */}
      {leftView === LeftView.SampleGraph && (
        <Suspense fallback={<LoadingPage  />}>
          <SampleGraph
            mobile={mobile}
            measurementType="vibration"
            samples={samples}
            selectedSample={selectedSample}
            setSelectedSample={setSelectedSample}
            toFFTLayers={(sample) => {
              setLeftView(LeftView.FFTLayers)
              fetch(
                `/get_org/${encodeURIComponent(
                  localStorage
                    .getItem("currentTab")
                    ?.split("/")[0]
                    .split("$")[2] as string
                )}`
              ).then((data) => data.json())
                .then((org_id) =>
                  addFFTLayer([sample.id], FFT_LAYER_COLORS[0], org_id)
                ).catch(e => console.log(e)
              ).finally(() => loadLayers());
            }}
            onClose={() => setLeftView(LeftView.TrendsGraph)}
            tagEditable={!!sensorId}
            readonly={readonly}
          />
        </Suspense>
      )}

      {/* Trends View */}
      {leftView === LeftView.TrendsGraph && (
        <TrendsGraph
          mobile={mobile}
          sensorId={sensorId}
          folderPath={folderPath}
          measurementType="vibration"
          axis={axis ?? "x"}
          openGraphManager={() => setLeftView(LeftView.FFTLayers)}
          samples={samples}
          goToSample={goToSample}
          openSensorSettings={sensorId ? () => setLeftView(LeftView.SensorSettings) : undefined}
          machineSettings={sensorId ? machineSettings : undefined}
        />
      )}

      {/* Graph Manager View */}
      {leftView === LeftView.FFTLayers && (
        <Suspense fallback={<LoadingPage  />}>
          <FFTLayers
            mobile={mobile}
            layers={fftLayers}
            loadLayers={loadLayers}
            samples={samples}
            onClose={() => setLeftView(LeftView.TrendsGraph)}
            goToSample={goToSample}
            readonly={readonly}
          />
        </Suspense>
      )}

      {/* Sensor Settings */}
      {sensorId && leftView === LeftView.SensorSettings && (
        <Suspense fallback={<LoadingPage  />}>
          {<MachineSettingsView
            mobile={mobile}
            sensorId={sensorId}
            onClose={() => setLeftView(LeftView.TrendsGraph)}
            machineSettings={machineSettings}
            setMachineSettings={setMachineSettings}
          />}
        </Suspense>
      )}

      {/* Spectrum Analysis View */}
      {rightView === RightView.FFTAnalysis && (
        <FFTAnalysis
          mobile={mobile}
          openGraphManager={() => setLeftView(LeftView.FFTLayers)}
          sensorId={sensorId}
          folderPath={folderPath}
          layers={fftLayers}
          samples={samples}
          machineSettings={machineSettings}
          measurementType="vibration"
          axis={axis ?? "x"}
        />
      )}
    </div>
  );
};

export default Dashboard;
