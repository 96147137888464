import styles from "../../../Styles/ResultPreview.module.css";
import Tag from "../../../Images/Tag.png";
import Settings from "../../../Images/SettingsIcon.png";
import { useState } from "react";
import PopUp from "../../PopUp";

const ResultPreview = ({
  resultSelected,
  setDownloadModalOpen,
  setSaveModalOpen,
  setOpenedFile,
  setSensorSamples,
  setRelatedSamplesSelected,
  relatedSamplesSelected,
  tags,
  SensorSamples,
  RelatedSamples,
}: any) => {
  const [previewMachineSettings, setPreviewMachineSettings] = useState<any>([]);
  const [previewMachineSettingsModalOpen, setPreviewMachineSettingsModalOpen] =
    useState(false);

  return (
    <>
      <PopUp
        isOpen={previewMachineSettingsModalOpen}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setPreviewMachineSettingsModalOpen(false);
          }
        }}
        title="Machine Settings"
      >
        {!previewMachineSettings[0]?.rolling_elem_settings_active &&
          !previewMachineSettings[0]?.motor_settings_active &&
          !previewMachineSettings[0]?.gear_settings_active && (
            <div>No machine settings.</div>
          )}
        <div className={styles.machineSettings}>
          {previewMachineSettings[0]?.rolling_elem_settings_active && (
            <div>
              <h2>Rolling Elements</h2>
              <div>
                # of rolling elements:{" "}
                {previewMachineSettings[0]?.num_of_rolling_elems}
              </div>
              <div>
                Angle of contact: {previewMachineSettings[0]?.angle_of_contact}
              </div>
              <div>
                Pitch diameter: {previewMachineSettings[0]?.pitch_diameter}
              </div>
              <div>
                Rolling element diameter:{" "}
                {previewMachineSettings[0]?.rolling_elem_diameter}
              </div>
            </div>
          )}
          {previewMachineSettings[0]?.motor_settings_active && (
            <div>
              <h2>Motor</h2>
              <div>Motor speed: {previewMachineSettings[0]?.motor_speed}</div>
              <div># of poles: {previewMachineSettings[0]?.num_of_poles}</div>
              <div># of rotors: {previewMachineSettings[0]?.num_of_rotors}</div>
              <div>
                # of fan blades: {previewMachineSettings[0]?.num_of_fan_blades}
              </div>
            </div>
          )}
          {previewMachineSettings[0]?.gear_settings_active && (
            <div>
              <h2>Gears</h2>
              <div># of teeth: {previewMachineSettings[0]?.num_of_teeth}</div>
              <div>
                # of teeth in: {previewMachineSettings[0]?.num_of_teeth_in}
              </div>
              <div>
                # of teeth out: {previewMachineSettings[0]?.num_of_teeth_out}
              </div>
              <div>
                # of assembly phases:{" "}
                {previewMachineSettings[0]?.num_of_rolling_elems}
              </div>
            </div>
          )}
        </div>
      </PopUp>
      <div
        className={`${styles.preview} ${
          resultSelected?.type === "researcher"
            ? styles.hasResearcherDetails
            : ""
        }`}
      >
        {resultSelected?.type === "researcher" ? (
          <>
            <div className={styles.researcherDetails}>
              <div className={styles.researcherDetail}>
                {resultSelected?.institution
                  ? resultSelected.institution
                  : "No institution"}
              </div>
              <div className={styles.researcherDetail}>
                {resultSelected?.description
                  ? resultSelected.description
                  : "No description"}
              </div>
              <div className={styles.researcherDetail}>
                {resultSelected?.social_media &&
                  Object.entries(resultSelected?.social_media).map(
                    (socialMedia, i) => (
                      <div className={styles.socialMedia} key={i}>
                        <div
                          className={`${styles.socialMediaIcon} ${socialMedia[0]}`}
                        />
                        <div className={styles.socialMediaLink}>
                          <a
                            href={socialMedia[1] as string}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {socialMedia[0].split("-").join(" ")}
                          </a>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.infos} style={{ marginBottom: "1.5rem" }}>
              <div className={styles.info}>
                <span>
                  Type:{" "}
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {resultSelected?.type
                      ? resultSelected.type[0].toUpperCase() +
                        resultSelected.type.slice(1)
                      : ""}
                  </span>
                </span>
              </div>
              <div className={styles.info}>
                <img alt={"tag"} src={Tag} />
                <span>Tags:</span>
                {resultSelected?.tags?.length === 0 ? (
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    No tags.
                  </span>
                ) : (
                  <>
                    {resultSelected?.tags?.map((tag: any, i: number) => (
                      <div
                        style={{
                          backgroundColor: tag.color,
                        }}
                        key={i}
                        className={styles.tag}
                      >
                        {tag.value}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div
                className={`${styles.info} ${styles.button}`}
                onClick={() => {
                  fetch(`/sensor_machine_settings/${resultSelected?.sensor_id}`)
                    .then((data) => data.json())
                    .then((res) => {
                      setPreviewMachineSettings(res);
                      setPreviewMachineSettingsModalOpen(true);
                    });
                }}
              >
                <img alt={"settings"} src={Settings} />
                <span>Machine Settings</span>
              </div>
            </div>
            {resultSelected?.samples ? (
              <SensorSamples
                sensor_id={resultSelected?.sensor_id as number}
                samplesSelected={resultSelected?.samples}
                setSamplesSelected={setSensorSamples}
                tags={tags}
              />
            ) : (
              <RelatedSamples
                sample_id={resultSelected?.id as number}
                isVibration={resultSelected?.measurement_type === "vibration"}
                samplesSelected={relatedSamplesSelected}
                setSamplesSelected={setRelatedSamplesSelected}
              />
            )}
            <div className={styles.actionButtons}>
              <div
                className={styles.actionButton}
                onClick={() => {
                  setDownloadModalOpen(true);
                  if (resultSelected?.samples) {
                    setOpenedFile({
                      name: resultSelected?.name,
                      content: resultSelected?.samples.map((sample: any) => ({
                        name: sample.name ? sample.name : sample.mtime,
                        type: sample.measurement_type,
                        axis: sample.axis,
                        id: sample.id,
                        timestamp: sample.mtime,
                        sampling_frequency: sample.sampling_frequency,
                        raw_data: sample.raw_data.map(
                          (point: number, i: number) => {
                            return {
                              x: i / sample.sampling_frequency,
                              y: point,
                            };
                          }
                        ),
                      })),
                    });
                  } else {
                    setOpenedFile({
                      name: resultSelected?.name
                        ? resultSelected.name
                        : resultSelected?.mtime,
                      type: resultSelected?.measurement_type,
                      axis: resultSelected?.axis,
                      id: resultSelected?.id,
                      timestamp: resultSelected?.mtime,
                      sampling_frequency: resultSelected?.sampling_frequency,
                      raw_data: resultSelected?.raw_data?.map(
                        (point: number, i: number) => {
                          return {
                            x: i / resultSelected?.sampling_frequency!,
                            y: point,
                          };
                        }
                      ),
                    });
                  }
                }}
              >
                <div className={`${styles.downloadIcon} ${styles.icon}`} />
                Download Data
              </div>
              <div
                className={styles.actionButton}
                onClick={() => {
                  setSaveModalOpen(true);
                  if (resultSelected?.samples) {
                    setOpenedFile({
                      name: resultSelected?.name,
                      content: resultSelected?.samples.map((sample: any) => ({
                        name: sample.name ? sample.name : sample.mtime,
                        type: sample.measurement_type,
                        axis: sample.axis,
                        id: sample.id,
                        timestamp: sample.mtime,
                        sampling_frequency: sample.sampling_frequency,
                        raw_data: sample.raw_data.map(
                          (point: number, i: number) => {
                            return {
                              x: i / sample.sampling_frequency,
                              y: point,
                            };
                          }
                        ),
                      })),
                    });
                  } else {
                    setOpenedFile({
                      name: resultSelected?.name
                        ? resultSelected.name
                        : resultSelected?.mtime,
                      type: resultSelected?.measurement_type,
                      axis: resultSelected?.axis,
                      id: resultSelected?.id,
                      timestamp: resultSelected?.mtime,
                      sampling_frequency: resultSelected?.sampling_frequency,
                      raw_data: resultSelected?.raw_data?.map(
                        (point: number, i: number) => {
                          return {
                            x: i / resultSelected?.sampling_frequency!,
                            y: point,
                          };
                        }
                      ),
                    });
                  }
                }}
              >
                <div className={`${styles.saveIcon} ${styles.icon}`} />
                Save Data
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ResultPreview;
