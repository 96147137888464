import { TrendThreshold } from "../Components/AnalysisPage/HTTPRequests/Types";

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getThresholdName = (threshold: TrendThreshold) => {
  return `${threshold.compared_to.split("_").map(s => {
    if (["x", "y", "z"].includes(s)) return "";
    if (s === "rms") return "RMS";
    if (s === "kurtosis") return "Kurtosis";
    if (s === "p2p") return "Peak to Peak";
    return capitalizeFirstLetter(s);
  }).join(" ")} ${capitalizeFirstLetter(threshold.type)}${threshold.is_iso ? " (ISO)" : ""}`;
}