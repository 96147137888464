import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "../../../Styles/SearchPage.module.css";
import Tag from "../../../Images/Tag.png";
import Plus from "../../../Images/+.png";
import Delete from "../../../Images/X.png";
import Settings from "../../../Images/SettingsIcon.png";
import PageWrapperTagPopUp from "../../AnalysisPage/TagPopUp";
import PopUp from "../../PopUp";
import Download from "../../../Images/DownloadIcon.png";
import SoundWave from "../../../Images/SoundWave.png";
import ViewResearcher from "../../../Images/Group.png";
import Save from "../../../Images/AddIcon.png";
import {
  Data,
  FileError,
  getFiles,
  saveCSV,
  saveFiles,
  saveJSON,
} from "../../../Utils/FileUtils";
import FileErrorModal from "../../FileManager/FileErrorModal/FileErrorModal";
import ChooseLocation from "../../FileManager/ChooseLocation/ChooseLocation";
import RelatedSamples from "./RelatedSamples";
import SensorSamples from "./SensorSamples";
import ResultPreview from "./ResultPreview";
import { AppContext } from "../../App";
import { useOutsideDetect } from "../../../Utils/DropdownUtils";
import { capitalizeFirstLetter } from "../../../Utils/Utils";

/**
 * Page to search samples and researchers.
 * The user can find real-life samples from industry and save them or download them.
 * The user can also find researchers and view their profile.
 * They can filter by category, search query, tags, and machine settings.
 * Props:
 * - user_id: the user's id (from OAuth, not the database id)
 * - sortDevicesTabLast: function to sort the devices tab last (function from App.tsx)
 * - ifAuthorized: function to check if the user is authorized (function from App.tsx)
 * - initialCategory: initial category to filter by (either researcher or samples)
 */
const SearchPage = ({
  user_id,
  sortDevicesTabLast,
  ifAuthorized,
  initialCategory,
}: {
  user_id: string;
  sortDevicesTabLast: any;
  ifAuthorized: any;
  initialCategory: string;
}) => {
  const { tabInstance } =  useContext(AppContext) ?? {};
  // Query Parameters
  const [checkedCategories, setCheckedCategories] = useState({
    researcher: initialCategory === "researcher",
    vibrationX: initialCategory === "samples",
    vibrationY: initialCategory === "samples",
    vibrationZ: initialCategory === "samples",
    audio: initialCategory === "samples",
    temperature: initialCategory === "samples",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [includeSavedSamples, setIncludeSavedSamples] = useState(true);
  const [groupBySensor, setGroupBySensor] = useState(true);

  const [tags, setTags] = useState<any[]>([]);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [orderBy, setOrderBy] = useState<{ column: string; direction: string }>(
    { column: "name", direction: "asc" }
  );

  const categoriesDropdownBtnRef = useRef<HTMLDivElement>(null);
  const categoriesDropdownMenuRef = useRef<HTMLDivElement>(null);
  useOutsideDetect(categoriesDropdownMenuRef, categoriesDropdownBtnRef, () => {
    setCategoriesDropdownOpen(false);
  });

  const [machineSettings, setMachineSettings] = useState<
    {
      name: string;
      settings: {
        setting: string;
        key: string;
        comparator: string;
        value: string;
      }[];
    }[]
  >([
    {
      name: "Rolling Elements",
      settings: [
        {
          setting: "# of rolling elements",
          key: "num_of_rolling_elems",
          comparator: "=",
          value: "",
        },
        {
          setting: "Angle of contact",
          key: "angle_of_contact",
          comparator: "=",
          value: "",
        },
        {
          setting: "Pitch diameter",
          key: "pitch_diameter",
          comparator: "=",
          value: "",
        },
        {
          setting: "Rolling element diameter",
          key: "rolling_elem_diameter",
          comparator: "=",
          value: "",
        },
      ],
    },
    {
      name: "Motor",
      settings: [
        {
          setting: "Motor speed",
          key: "motor_speed",
          comparator: "=",
          value: "",
        },
        {
          setting: "# of poles",
          key: "num_of_poles",
          comparator: "=",
          value: "",
        },
        {
          setting: "# of rotors",
          key: "num_of_rotors",
          comparator: "=",
          value: "",
        },
        {
          setting: "# of fan blades",
          key: "num_of_fan_blades",
          comparator: "=",
          value: "",
        },
      ],
    },
    {
      name: "Gears",
      settings: [
        {
          setting: "# of teeth",
          key: "num_of_teeth",
          comparator: "=",
          value: "",
        },
        {
          setting: "# of teeth in",
          key: "num_of_teeth_in",
          comparator: "=",
          value: "",
        },
        {
          setting: "# of teeth out",
          key: "num_of_teeth_out",
          comparator: "=",
          value: "",
        },
        {
          setting: "# of assembly phases",
          key: "num_of_assembly_phases",
          comparator: "=",
          value: "",
        },
      ],
    },
  ]);
  const [machineSettingsModalOpen, setMachineSettingsModalOpen] =
    useState(false);

  const [categoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);

  // Search Results
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [displayedResults, setDisplayedResults] = useState<any[]>([]);

  const searchResultsRef = useRef<HTMLDivElement>(null);

  // Preview
  const [resultSelected, setResultSelected] = useState<{
    id?: number;
    name?: string;
    sensor_id?: number;
    mtime?: string;
    measurement_type?: string;
    axis?: string;
    sampling_frequency?: number;
    raw_data?: number[];
    tags?: { value: string; color: string }[];
    type?: string;
    institution?: string;
    description?: string;
    social_media?: any;
    samples?: any[];
  } | null>(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  // Download && save file
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [openedFile, setOpenedFile] = useState<Data | null>(null);
  const [saveLocation, setSaveLocation] = useState("");
  const [fileErrorModalOpen, setFileErrorModalOpen] =
    useState<FileError | null>(null);
  const [userFiles, setUserFiles] = useState<{
    orgs: any[];
    folders: any[];
    samples: any[];
  }>({
    orgs: [],
    folders: [],
    samples: [],
  });
  const [files, setFiles] = useState<any[]>([]);

  const [relatedSamplesSelected, setRelatedSamplesSelected] = useState<any[]>(
    []
  );

  // Loading state
  const [loading, setLoading] = useState(false);

  const loadSearchResults = useCallback(async () => {
    const res = await fetch(
      `/search_results/${encodeURIComponent(
        JSON.stringify({
          query: searchQuery,
          tags: tags.map((tag) => tag.value),
          categories: Object.keys(checkedCategories).filter(
            (category) => (checkedCategories as any)[category]
          ),
          machine_settings: machineSettings.flatMap((category) => [
            ...category.settings,
          ]),
          include_saved_samples: includeSavedSamples,
          user_id: user_id,
          order_by: orderBy.column,
          order_direction: orderBy.direction,
          offset: 0,
          group_by_sensor: groupBySensor,
        })
      )}`
    ).then((data) => data.json());
    setSearchResults(res);
  }, [
    checkedCategories,
    includeSavedSamples,
    machineSettings,
    searchQuery,
    tags,
    user_id,
    orderBy,
    groupBySensor,
  ]);

  // Load more results when scrolling down
  const loadMoreResults = useCallback(async () => {
    const res = await fetch(
      `/search_results/${encodeURIComponent(
        JSON.stringify({
          query: searchQuery,
          tags: tags.map((tag) => tag.value),
          categories: Object.keys(checkedCategories).filter(
            (category) => (checkedCategories as any)[category]
          ),
          machine_settings: machineSettings.flatMap((category) => [
            ...category.settings,
          ]),
          include_saved_samples: includeSavedSamples,
          user_id: user_id,
          order_by: orderBy.column,
          order_direction: orderBy.direction,
          offset: searchResults.length,
          group_by_sensor: groupBySensor,
        })
      )}`
    ).then((data) => data.json());
    setSearchResults((searchResults) => [...searchResults, ...res]);
  }, [
    checkedCategories,
    includeSavedSamples,
    machineSettings,
    orderBy,
    searchQuery,
    searchResults,
    tags,
    user_id,
    groupBySensor,
  ]);

  // Add scroll event listener to load more results
  const loadingRef = useRef(false);
  useEffect(() => {
    const handleScroll = () => {
      if (searchResultsRef.current === null) return;
      if (
        searchResultsRef.current.scrollTop +
          searchResultsRef.current.clientHeight >=
          searchResultsRef.current.scrollHeight - 100 &&
        !loadingRef.current
      ) {
        loadingRef.current = true;
        loadMoreResults().then(() => {
          loadingRef.current = false;
        });
      }
    };

    const searchResults = searchResultsRef.current;
    searchResults?.addEventListener("scroll", handleScroll);
    return () => {
      searchResults?.removeEventListener("scroll", handleScroll);
    };
  }, [loadMoreResults]);

  // Groups the samples by sensors when the groupBySensor state changes
  useEffect(() => {
    if (groupBySensor && !checkedCategories.researcher) {
      const groupedResults: any[] = [];
      searchResults.forEach((result: any) => {
        if (
          !groupedResults.map((e) => e.sensor_id).includes(result.sensor_id) &&
          result.type !== "researcher"
        ) {
          groupedResults.push({
            sensor_id: result.sensor_id,
            name: `Sensor #${result.sensor_id}`,
            type: Array.from(
              new Set(
                searchResults
                  .filter((r: any) => r.sensor_id === result.sensor_id)
                  .map((r: any) =>
                    r.measurement_type === "vibration"
                      ? `Vibration: ${Array.from(
                          new Set(
                            searchResults
                              .filter(
                                (r2: any) =>
                                  r2.sensor_id === result.sensor_id &&
                                  r2.measurement_type === "vibration"
                              )
                              .map((r2) => r2.axis)
                          )
                        )
                          .sort()
                          .join(", ")}`
                      : capitalizeFirstLetter(r.measurement_type)
                  )
              )
            ).join(", "),
            tags: searchResults
              .filter((r: any) => r.sensor_id === result.sensor_id)
              .flatMap((r: any) => r.tags)
              .filter((tag: any, index: number, self: any[]) => {
                return self.findIndex((t) => t.value === tag.value) === index;
              }),
            samples: searchResults.filter(
              (r: any) => r.sensor_id === result.sensor_id
            ),
          });
        }
      });
      setDisplayedResults(groupedResults);
    } else {
      setDisplayedResults(searchResults);
    }
  }, [groupBySensor, searchResults, checkedCategories]);

  // Load user files in files state
  useEffect(() => {
    if (userFiles.folders && userFiles.samples && userFiles.orgs) {
      const newFiles: any = [
        ...userFiles.orgs.map((o: any) => {
          return {
            isOrg: true,
            dbID: o.id,
            key: o.file_path,
          };
        }),
        ...userFiles.folders.map((f: any) => {
          return {
            dbID: f.id,
            key: f.file_path,
          };
        }),
        ...userFiles.samples.map((s: any) => {
          return {
            dbID: s.id,
            key: s.file_path,
          };
        }),
      ].filter((file) => !!file);

      // Pushing loaded files into state
      setFiles(newFiles);
    }
  }, [userFiles, user_id]);

  // Initialy load the search results
  useEffect(() => {
    setLoading(true);
    loadSearchResults().then(() => setLoading(false));
  }, [loadSearchResults]);

  // Initialy load user files
  useEffect(() => {
    const loadUserFiles = async () => {
      setUserFiles(await getFiles(user_id, "researcher"));
    };
    loadUserFiles();
  }, [user_id]);

  // Update the tags state when a tag is added or removed
  const updateTags = async ({
    updateType,
    val,
    tags,
  }: {
    updateType?: string | undefined;
    val?: string | undefined;
    tags?: { value: string; color: string }[];
  }) => {
    if (updateType === "add") {
      setTags((current) => [
        ...current,
        ...tags?.filter((tag) => tag.value === val)!,
      ]);
    } else if (updateType === "delete") {
      setTags((current) => current.filter((tag) => tag.value !== val));
    }
  };

  const setSensorSamples = useCallback((func: (curr: any[]) => any[]) => {
    setResultSelected((current) => {
      return {
        ...current,
        samples: func(current?.samples as any[]),
      };
    });
  }, []);

  return (
    <>
      {/* Sample/Sensor Preview PopUp */}
      <PopUp
        isOpen={previewModalOpen}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setPreviewModalOpen(false);
          }
        }}
        title={
          resultSelected?.name ? resultSelected?.name : resultSelected?.mtime
        }
        closeFunction={() => {
          setResultSelected(null);
          setRelatedSamplesSelected([]);
        }}
      >
        <ResultPreview
          resultSelected={resultSelected}
          setDownloadModalOpen={setDownloadModalOpen}
          setSaveModalOpen={setSaveModalOpen}
          setOpenedFile={setOpenedFile}
          setSensorSamples={setSensorSamples}
          setRelatedSamplesSelected={setRelatedSamplesSelected}
          relatedSamplesSelected={relatedSamplesSelected}
          tags={tags}
          SensorSamples={SensorSamples}
          RelatedSamples={RelatedSamples}
        />
      </PopUp>
      {/* File Error PopUp */}
      <PopUp
        isOpen={fileErrorModalOpen !== null}
        setIsOpen={() => setFileErrorModalOpen(null)}
        title={
          fileErrorModalOpen
            ? `${fileErrorModalOpen?.fileType} ${fileErrorModalOpen?.actionType} Failed`
            : ""
        }
      >
        <FileErrorModal fileError={fileErrorModalOpen} />
      </PopUp>
      {/* Download Files PopUp */}
      <PopUp
        
        isOpen={downloadModalOpen}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setDownloadModalOpen(false);
          }
        }}
        helpUrl={`/downloading_data`}
        title={
          <>
            Download Data
            <div className={styles.helpIcon} />
          </>
        }
        closeFunction={() => setRelatedSamplesSelected([])}
      >
        <div>
          <div className={styles.downloadModalBtns}>
            {!previewModalOpen && !openedFile?.content && (
              <div
                onClick={() => {
                  setPreviewModalOpen(true);
                  setResultSelected(
                    searchResults.filter((r) => r.id === openedFile?.id)[0]
                  );
                  setDownloadModalOpen(false);
                }}
                className={`${styles.seeRelatedSamples} ${styles.actionButton}`}
              >
                <div className={`${styles.sampleIcon} ${styles.icon}`} />
                See Related Samples
              </div>
            )}
            <div
              className={styles.downloadModalBtn}
              onClick={() => {
                if (relatedSamplesSelected.length === 0 && openedFile?.id) {
                  fetch(
                    `/get_download_data/${encodeURIComponent(
                      JSON.stringify({
                        measurement_id: openedFile?.id,
                      })
                    )}`
                  )
                    .then((data) => data.json())
                    .then((data) => saveCSV(data));
                } else if (openedFile?.id) {
                  Promise.all(
                    [openedFile, ...relatedSamplesSelected].map((f) =>
                      fetch(
                        `/get_download_data/${encodeURIComponent(
                          JSON.stringify({
                            measurement_id: f?.id,
                          })
                        )}`
                      ).then((data) => data.json())
                    )
                  ).then((data) =>
                    saveCSV({
                      name: openedFile?.name
                        ? openedFile?.name
                        : openedFile?.timestamp,
                      content: data,
                    })
                  );
                } else {
                  Promise.all(
                    (openedFile?.content as Data[]).map((f) =>
                      fetch(
                        `/get_download_data/${encodeURIComponent(
                          JSON.stringify({
                            measurement_id: f?.id,
                          })
                        )}`
                      ).then((data) => data.json())
                    )
                  ).then((data) =>
                    saveCSV({
                      name: openedFile?.name
                        ? openedFile?.name
                        : openedFile?.timestamp,
                      content: data,
                    })
                  );
                }
              }}
            >
              <div className={`${styles.tableIcon} ${styles.icon}`} />
              Save Data as <em>CSV</em>
            </div>
            <div
              className={styles.downloadModalBtn}
              onClick={() => {
                if (relatedSamplesSelected.length === 0 && openedFile?.id) {
                  fetch(
                    `/get_download_data/${encodeURIComponent(
                      JSON.stringify({
                        measurement_id: openedFile?.id,
                      })
                    )}`
                  )
                    .then((data) => data.json())
                    .then((data) => saveJSON(data));
                } else if (openedFile?.id) {
                  Promise.all(
                    [openedFile, ...relatedSamplesSelected].map((f) =>
                      fetch(
                        `/get_download_data/${encodeURIComponent(
                          JSON.stringify({
                            measurement_id: f?.id,
                          })
                        )}`
                      ).then((data) => data.json())
                    )
                  ).then((data) =>
                    saveJSON({
                      name: openedFile?.name
                        ? openedFile?.name
                        : openedFile?.timestamp,
                      content: data,
                    })
                  );
                } else {
                  Promise.all(
                    (openedFile?.content as Data[]).map((f) =>
                      fetch(
                        `/get_download_data/${encodeURIComponent(
                          JSON.stringify({
                            measurement_id: f?.id,
                          })
                        )}`
                      ).then((data) => data.json())
                    )
                  ).then((data) =>
                    saveJSON({
                      name: openedFile?.name
                        ? openedFile?.name
                        : openedFile?.timestamp,
                      content: data,
                    })
                  );
                }
              }}
            >
              <div className={`${styles.jsonIcon} ${styles.icon}`} />
              Save Data as <em>JSON</em>
            </div>
          </div>
        </div>
      </PopUp>
      {/* Save Files to Sample Manager PopUp */}
      <PopUp
        
        isOpen={saveModalOpen}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setSaveModalOpen(false);
          }
        }}
        helpUrl={`/the_sample_manager`}
        title={
          <>
            Save Data
            <div className={styles.helpIcon} />
          </>
        }
        confirmBtnTxt={"Save"}
        confirmBtnFunc={async () => {
          await saveFiles(
            `${saveLocation}${
              openedFile?.content ? `New:{${openedFile?.name}}/` : ""
            }`,
            userFiles.folders,
            files,
            user_id,
            setFileErrorModalOpen,
            openedFile?.content
              ? (openedFile.content as Data[]).map((f: any) => ({
                  name: f.name ? f.name : f.timestamp,
                  id: f.id,
                  type: "measurement",
                  upload: false,
                }))
              : [
                  {
                    name: openedFile?.name
                      ? openedFile.name
                      : openedFile?.timestamp,
                    id: openedFile?.id,
                    type: "measurement",
                    upload: false,
                  },
                  ...relatedSamplesSelected.map((sample) => {
                    return {
                      name: sample?.name ? sample.name : sample?.timestamp,
                      id: sample?.id,
                      type: "measurement",
                      upload: false,
                    };
                  }),
                ]
          );
          setUserFiles(await getFiles(user_id, "researcher"));
          tabInstance.refresh();
        }}
        closeFunction={() => {
          setOpenedFile(null);
          setSaveLocation("");
          setRelatedSamplesSelected([]);
        }}
      >
        <div className={styles.savePopUp}>
          {!previewModalOpen && !openedFile?.content && (
            <div
              onClick={() => {
                setPreviewModalOpen(true);
                setResultSelected(
                  searchResults.filter((r) => r.id === openedFile?.id)[0]
                );
                setSaveModalOpen(false);
              }}
              className={`${styles.seeRelatedSamples} ${styles.actionButton}`}
            >
              <div className={`${styles.sampleIcon} ${styles.icon}`} />
              See Related Samples
            </div>
          )}
          <ChooseLocation
            location={saveLocation}
            setLocation={setSaveLocation}
            orgs={userFiles.orgs}
            folders={userFiles.folders}
            user_id={user_id}
          />
        </div>
      </PopUp>
      {/* Tag Filter PopUp */}
      <PageWrapperTagPopUp
        tagModalOpen={tagModalOpen}
        setTagModalOpen={setTagModalOpen}
        dataId={null}
        tags={tags}
        updateTags={updateTags}
        tagEditable={false}
        onClose={() => {
          setLoading(true);
          loadSearchResults().then(() => setLoading(false));
        }}
      />
      {/* Machine Settings Filters PopUp */}
      <PopUp
        isOpen={machineSettingsModalOpen}
        setIsOpen={setMachineSettingsModalOpen}
        title="Machine Settings"
        closeFunction={() => {
          setLoading(true);
          loadSearchResults().then(() => setLoading(false));
        }}
      >
        <div className={styles.machineSettings}>
          {machineSettings.map((category, i) => (
            <>
              <h2 key={i}>{category.name}</h2>
              {category.settings.map((setting, j) => (
                <div
                  className={styles.machineSettingsElement}
                  key={`${i}.${j}`}
                >
                  <span>{setting.setting}</span>
                  <select
                    value={setting.comparator}
                    onChange={(e) => {
                      setMachineSettings((current) => {
                        const newSettings = [...current];
                        newSettings[i].settings[j].comparator = e.target.value;
                        return newSettings;
                      });
                    }}
                  >
                    <option value="=">&#61;</option>
                    <option value="<">&#60;</option>
                    <option value=">">&#62;</option>
                    <option value="<=">&#8804;</option>
                    <option value=">=">&#8805;</option>
                  </select>
                  <input
                    type="text"
                    size={10}
                    inputMode="numeric"
                    value={setting.value}
                    onChange={(e) => {
                      setMachineSettings((current) => {
                        const newSettings = [...current];
                        newSettings[i].settings[j].value = e.target.value;
                        return newSettings;
                      });
                    }}
                  />
                </div>
              ))}
            </>
          ))}
        </div>
      </PopUp>
      {loading && (
        <div className={styles.loading}></div> // This div prevents people to modify the filters too fast, could add a loading modal in the future
      )}
      <div className="page">
        <div className={`content ${styles.container}`}>
          <form
            className={styles.searchForm}
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              loadSearchResults().then(() => setLoading(false));
            }}
          >
            <div className={styles.searchBar}>
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className={styles.categories}>
                <div
                  className={styles.categoriesDropdownTitle}
                  onClick={() => {
                    if (categoriesDropdownOpen) {
                      setLoading(true);
                      loadSearchResults().then(() => setLoading(false));
                    }
                    setCategoriesDropdownOpen(!categoriesDropdownOpen);
                  }}
                  ref={categoriesDropdownBtnRef}
                >
                  Categories
                  <span
                    className={styles.dropdownArrow}
                    style={{
                      transform: categoriesDropdownOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  ></span>
                </div>
                {categoriesDropdownOpen && (
                  <div className={styles.categoriesDropdown} ref={categoriesDropdownMenuRef}>
                    <div className={styles.category}>
                      <input
                        type="checkbox"
                        checked={checkedCategories.researcher}
                        onChange={() => {
                          setCheckedCategories((current) =>
                            current.researcher
                              ? { ...current, researcher: false }
                              : {
                                  vibrationX: false,
                                  vibrationY: false,
                                  vibrationZ: false,
                                  temperature: false,
                                  audio: false,
                                  researcher: true,
                                }
                          );
                        }}
                      />
                      <span>Researchers</span>
                    </div>
                    <div className={styles.category}>
                      <input
                        type="checkbox"
                        checked={
                          checkedCategories.audio &&
                          checkedCategories.temperature &&
                          checkedCategories.vibrationX &&
                          checkedCategories.vibrationY &&
                          checkedCategories.vibrationZ
                        }
                        onChange={() => {
                          setCheckedCategories((current) => {
                            const isChecked =
                              current.audio &&
                              current.temperature &&
                              current.vibrationX &&
                              current.vibrationY &&
                              current.vibrationZ;
                            return {
                              researcher: isChecked
                                ? current.researcher
                                : false,
                              vibrationX: !isChecked,
                              vibrationY: !isChecked,
                              vibrationZ: !isChecked,
                              audio: !isChecked,
                              temperature: !isChecked,
                            };
                          });
                        }}
                      />
                      <span>Samples</span>
                      <div className={styles.category}>
                        <input
                          type="checkbox"
                          checked={
                            checkedCategories.vibrationX &&
                            checkedCategories.vibrationY &&
                            checkedCategories.vibrationZ
                          }
                          onChange={() => {
                            setCheckedCategories((current) => {
                              const isChecked =
                                current.vibrationX &&
                                current.vibrationY &&
                                current.vibrationZ;
                              return {
                                ...current,
                                researcher: isChecked
                                  ? current.researcher
                                  : false,
                                vibrationX: !isChecked,
                                vibrationY: !isChecked,
                                vibrationZ: !isChecked,
                              };
                            });
                          }}
                        />
                        <span>Vibration</span>
                        <div className={styles.category}>
                          <input
                            type="checkbox"
                            checked={checkedCategories.vibrationX}
                            onChange={() => {
                              setCheckedCategories((current) =>
                                current.vibrationX
                                  ? { ...current, vibrationX: false }
                                  : {
                                      ...current,
                                      researcher: false,
                                      vibrationX: true,
                                    }
                              );
                            }}
                          />
                          <span>X</span>
                        </div>
                        <div className={styles.category}>
                          <input
                            type="checkbox"
                            checked={checkedCategories.vibrationY}
                            onChange={() => {
                              setCheckedCategories((current) =>
                                current.vibrationY
                                  ? { ...current, vibrationY: false }
                                  : {
                                      ...current,
                                      researcher: false,
                                      vibrationY: true,
                                    }
                              );
                            }}
                          />
                          <span>Y</span>
                        </div>
                        <div className={styles.category}>
                          <input
                            type="checkbox"
                            checked={checkedCategories.vibrationZ}
                            onChange={() => {
                              setCheckedCategories((current) =>
                                current.vibrationZ
                                  ? { ...current, vibrationZ: false }
                                  : {
                                      ...current,
                                      researcher: false,
                                      vibrationZ: true,
                                    }
                              );
                            }}
                          />
                          <span>Z</span>
                        </div>
                      </div>
                      <div className={styles.category}>
                        <input
                          type="checkbox"
                          checked={checkedCategories.audio}
                          onChange={() => {
                            setCheckedCategories((current) =>
                              current.audio
                                ? { ...current, audio: false }
                                : { ...current, researcher: false, audio: true }
                            );
                          }}
                        />
                        <span>Audio</span>
                      </div>
                      <div className={styles.category}>
                        <input
                          type="checkbox"
                          checked={checkedCategories.temperature}
                          onChange={() => {
                            setCheckedCategories((current) =>
                              current.temperature
                                ? { ...current, temperature: false }
                                : {
                                    ...current,
                                    researcher: false,
                                    temperature: true,
                                  }
                            );
                          }}
                        />
                        <span>Temperature</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!checkedCategories.researcher && (
              <div className={styles.searchFilters}>
                <h2>Filters</h2>
                <div className={styles.filters}>
                  <div className={styles.filter}>
                    <img alt={"tag"} src={Tag} />
                    <span>Tags:</span>
                    {tags.length === 0 ? (
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        No tags.
                      </span>
                    ) : (
                      <>
                        {tags.map((tag: any, i: number) => {
                          return (
                            <div
                              style={{
                                backgroundColor: tag.color,
                              }}
                              key={i}
                              className={styles.tag}
                            >
                              {tag.value}
                              <img
                                alt={"remove"}
                                onClick={() =>
                                  updateTags({
                                    val: tag.value,
                                    updateType: "delete",
                                  })
                                }
                                className={styles.plusTag}
                                src={Delete}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                    <img
                      alt={"add"}
                      className={styles.plusTag}
                      src={Plus}
                      onClick={() => setTagModalOpen(true)}
                    />
                  </div>
                  <div
                    className={`${styles.filter} ${styles.button}`}
                    onClick={() =>
                      setMachineSettingsModalOpen(!machineSettingsModalOpen)
                    }
                  >
                    <img alt={"settings"} src={Settings} />
                    <span>Machine Settings</span>
                  </div>
                  <div className={styles.filter}>
                    <input
                      type="checkbox"
                      checked={includeSavedSamples}
                      onChange={() => {
                        setIncludeSavedSamples(!includeSavedSamples);
                        setLoading(true);
                        loadSearchResults().then(() => setLoading(false));
                      }}
                    />
                    <span>Include Saved Samples</span>
                  </div>
                  <div className={styles.filter}>
                    <input
                      type="checkbox"
                      checked={groupBySensor}
                      onChange={() => setGroupBySensor(!groupBySensor)}
                    />
                    <span>Group by Sensor</span>
                  </div>
                </div>
              </div>
            )}
          </form>
          <div className={styles.searchResults} ref={searchResultsRef}>
            <div
              className={styles.tableTitle}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setOrderBy({
                  column: "name",
                  direction: orderBy.direction === "asc" ? "desc" : "asc",
                })
              }
            >
              Name
              {orderBy.column === "name" && (
                <span
                  className={styles.dropdownArrow}
                  style={{
                    transform:
                      orderBy.direction === "asc"
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                ></span>
              )}
            </div>
            <div
              className={styles.tableTitle}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setOrderBy({
                  column: "type",
                  direction: orderBy.direction === "asc" ? "desc" : "asc",
                })
              }
            >
              Type
              {orderBy.column === "type" && (
                <span
                  className={styles.dropdownArrow}
                  style={{
                    transform:
                      orderBy.direction === "asc"
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                ></span>
              )}
            </div>
            <div className={styles.tableTitle}>Tags</div>
            <div className={styles.tableTitle}>Actions</div>
            {displayedResults.length === 0 ? (
              <div className={styles.noResults}>No Results</div>
            ) : (
              <>
                {displayedResults.map((result: any, i: number) => (
                  <>
                    <div className={styles.tableCell} key={`${i}.1`}>
                      {result.name ? result.name : result.mtime}
                    </div>
                    <div className={styles.tableCell} key={`${i}.2`}>
                      {capitalizeFirstLetter(result.type)}
                    </div>
                    <div className={styles.tableCell} key={`${i}.3`}>
                      {result.type === "researcher"
                        ? "N/A"
                        : result.tags?.map((tag: any, i: number) => {
                            return (
                              <div
                                style={{
                                  backgroundColor: tag.color,
                                }}
                                key={i}
                                className={styles.tag}
                              >
                                {tag.value}
                              </div>
                            );
                          })}
                    </div>
                    <div
                      className={`${styles.tableCell} ${styles.hasActionIcon}`}
                      key={`${i}.4`}
                    >
                      {result.type !== "researcher" && (
                        <img
                          className={styles.actionIcon}
                          alt="download"
                          src={Download}
                          onClick={() => {
                            if (result.samples) {
                              setOpenedFile({
                                name: result.name,
                                content: result.samples.map((sample: any) => ({
                                  name: sample.name
                                    ? sample.name
                                    : sample.mtime,
                                  type: sample.measurement_type,
                                  axis: sample.axis,
                                  id: sample.id,
                                  timestamp: sample.mtime,
                                  sampling_frequency: sample.sampling_frequency,
                                  raw_data: sample.raw_data.map(
                                    (point: number, i: number) => {
                                      return {
                                        x: i / sample.sampling_frequency,
                                        y: point,
                                      };
                                    }
                                  ),
                                })),
                              });
                            } else {
                              setOpenedFile({
                                name: result.name ? result.name : result.mtime,
                                type: result.measurement_type,
                                axis: result.axis,
                                id: result.id,
                                timestamp: result.mtime,
                                sampling_frequency: result.sampling_frequency,
                                raw_data: result.raw_data.map(
                                  (point: number, i: number) => {
                                    return {
                                      x: i / result.sampling_frequency,
                                      y: point,
                                    };
                                  }
                                ),
                              });
                            }
                            setDownloadModalOpen(true);
                          }}
                        />
                      )}
                      <img
                        className={styles.actionIcon}
                        alt="view"
                        src={
                          result.type === "researcher"
                            ? ViewResearcher
                            : SoundWave
                        }
                        onClick={() => {
                          setResultSelected(result);
                          setPreviewModalOpen(true);
                        }}
                      />
                      {result.type !== "researcher" && (
                        <img
                          className={styles.actionIcon}
                          alt="save"
                          src={Save}
                          onClick={() => {
                            if (result.samples) {
                              setOpenedFile({
                                name: result.name,
                                content: result.samples.map((sample: any) => ({
                                  name: sample.name
                                    ? sample.name
                                    : sample.mtime,
                                  type: sample.measurement_type,
                                  axis: sample.axis,
                                  id: sample.id,
                                  timestamp: sample.mtime,
                                  sampling_frequency: sample.sampling_frequency,
                                  raw_data: sample.raw_data.map(
                                    (point: number, i: number) => {
                                      return {
                                        x: i / sample.sampling_frequency,
                                        y: point,
                                      };
                                    }
                                  ),
                                })),
                              });
                            } else {
                              setOpenedFile({
                                name: result.name ? result.name : result.mtime,
                                type: result.measurement_type,
                                axis: result.axis,
                                id: result.id,
                                timestamp: result.mtime,
                                sampling_frequency: result.sampling_frequency,
                                raw_data: result.raw_data.map(
                                  (point: number, i: number) => {
                                    return {
                                      x: i / result.sampling_frequency,
                                      y: point,
                                    };
                                  }
                                ),
                              });
                            }
                            setSaveModalOpen(true);
                          }}
                        />
                      )}
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
